jQuery(document).ready(function($) {
	/* Header Alert */
	$( ".close-header-alert" ).click(function() {
		$(".alert-section").hide();

		if ( $('.content-section') != null) {
			if ($("body").innerWidth() <= 479 ) {
				$('.content-section').css('margin-top', 0);
			}
			else {
				$('.content-section').css('margin-top', 75);
			}

			$(".content-section.no-banner").css("margin-top", $( ".header" ).outerHeight());
		}

		setCookie("headerAlert", "isShown");
	});

	function setCookie(cname, cvalue) {
		document.cookie = cname + "=" + cvalue + ";path=/";
	}

	if ( document.cookie.indexOf("headerAlert=") == -1 ) {
		$(".alert-section").show();
	}

	// Mobile menu
	var $mobileMenuTrigger = $(".w-nav-button");
	var $mobileMenu = $(".mobile-navigation-menu");

	$mobileMenu.mmenu({
		screenReader: {
			aria: true,
			text: true
		},
		navbar: {
			add: true,
			title: ""
		},
		extensions: [
			"position-right",
			"fx-menu-slide",
			"fx-listitems-slide",
			"fx-panels-none",
		]
	});

	var API = $mobileMenu.data("mmenu");

	$mobileMenuTrigger.click(function() {
		API.open();
		$mobileMenuTrigger.addClass("w--open");
	});

	$mobileMenu.data('mmenu').bind('closed', function() {
		$mobileMenuTrigger.removeClass("w--open");
	});

	// Apply FitVids to YouTube videos
	if ($("body").innerWidth() <= 767) {
		$(".content-col").fitVids({
			ignore: "nofit"
		});
	}

	// Adds .hovered class to parent links when hovered over the dropdown wrapper
	$(document).ready(function() {
		var $nav = $('.dropdown');
		$nav.hover(
			function() { // hover in
				$(this).children('.nav-link-a').addClass('hovered');
			},
			function() { // hover out
				$(this).children('.nav-link-a').removeClass('hovered');
			}
		);
	});

	// When the window loads, note the scroll position and add and remove classes accordingly
	$(window).scroll(function() {
		if ($(this).scrollTop() > 200) {
			$('header').addClass("flexy-header");
		}
		else {
			$('header').removeClass("flexy-header");
		};
	});

	// Make the Back To Top slide smoothly
	$('#back-to-top').each(function() {
		$(this).click(function() {
			$('html,body').animate({
				scrollTop: 0
			}, 'slow');

			return false;
		})
	});

	// Use Alt as Caption
	$(".caption").wrap('<div class="caption-wrap"/>');
	$(".caption").each(function() {
		$(this).after('<p class="image-caption">' + $(this).attr('alt') + '</p>');
	});

	// Search Open
	$(".search-button").click(function() {
		if (!$(".live-chat-button").is(":visible")) { //if we are on mobile breakpoint
			if (!$(".search-form").hasClass('search-open')) { //and the search is not expanded
				$(".search-form").addClass('search-open'); //then expand the search
				$(".search-field").focus(); //give focus to the input field

				return false; //and do not do anything else
			}
		}
	});

	var interactions = [];

	// If accessed by an ipad remove transitions from drop-down menus
	if (navigator.userAgent.match(/iPad/i) != null) {
		interactions.push({
			slug: "dropdown-hover",
			name: "Dropdown Hover",
			value: {
				style: {},
				triggers: [{
					type: "hover",
					selector: ".dropdown-list",
					descend: true,
					stepsA: [{
						height: "auto"
					}],
					stepsB: [{
						height: "0px"
					}]
				}]
			}
		});
	}
	else {
		interactions.push({
			slug: "dropdown-hover",
			name: "Dropdown Hover",
			value: {
				style: {},
				triggers: [{
					type: "hover",
					selector: ".dropdown-list",
					descend: true,
					stepsA: [{
						height: "auto",
						transition: "height 300ms ease-in-out 0ms"
					}],
					stepsB: [{
						height: "0px",
						transition: "height 300ms ease-in-out 0ms"
					}]
				}]
			}
		});
	}

	interactions = interactions.concat([{
		slug: "mini-menu-open",
		name: "Mini Menu Open",
		value: {
			style: {},
			triggers: [{
				type: "click",
				selector: ".mini-menu-dropdown-list",
				siblings: true,
				stepsA: [{
					opacity: 1,
					height: "auto",
					transition: "opacity 400ms ease 0ms, height 500ms ease 0ms"
				}],
				stepsB: [{
					opacity: 0,
					height: "0px",
					transition: "transform 400ms ease 0ms, opacity 400ms ease 0ms, height 500ms ease 0ms",
					x: "0px",
					y: "0px",
					z: "0px"
				}]
			}]
		}
	},
	{
		slug: "work-overlay",
		name: "Work Overlay",
		value: {
			style: {},
			triggers: [{
				type: "hover",
				stepsA: [{
					height: "auto",
					transition: "height 500ms ease 0ms"
				}],
				stepsB: [{
					height: "0px",
					transition: "height 500ms ease 0ms"
				}]
			}]
		}
	},
	{
		slug: "search-open",
		name: "Search Open",
		value: {
			style: {},
			triggers: [{
				type: "click",
				selector: ".search-form",
				stepsA: [{
					width: "auto",
					transition: "width 500ms ease 0ms"
				}],
				stepsB: [{
					width: "30px",
					transition: "width 500ms ease 0ms"
				}]
			}]
		}
	},
	{
		slug: "search-open-2",
		name: "Search Open 2",
		value: {
			style: {},
			triggers: [{
				type: "click",
				selector: ".search-field",
				stepsA: [{
					opacity: 1,
					transition: "opacity 500ms ease 0ms"
				}],
				stepsB: [{
					opacity: 0,
					transition: "opacity 500ms ease 0ms"
				}]
			}]
		}
	}
	]);

	Webflow.require('ix').init(interactions);

	// Top Navigation menu
	if ($('.div-block-12').length) {
		if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
			$(".topnavigation-link").click(function(event) {
				if (!$(this).parents(".topnavigation").hasClass("open")) {
					$(".topnavigation").removeClass("open");
					event.preventDefault();
					$(this).parent(".topnavigation").addClass("open");
				}
			});
		}
		else {
			$(".topnavigation").hover(function() {
				$(this).addClass("open");
			}, function() {
				$(this).removeClass("open");
			});
		}
	}

	$(".live-chat-button").click(function() {
		var url = "https://athena.sitevizgpt.com/chatbot-iframe/7L9ud8iZK8ie0j-0E4TJ6";
		window.open(
			url,
			"_blank",
			"menubar=0,location=0,scrollbars=auto,resizable=1,status=0,width=500,height=700"
		);
	});
});
